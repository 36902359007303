import React, { useState, useEffect } from 'react';
import axios from '../../axiosConfig';
import CourtForm from './CourtForm';

const SelectCourt = ({ sportCenter }) => {
  const [courts, setCourts] = useState([]);
  const [selectedCourt, setSelectedCourt] = useState(null);
  const [showCourtForm, setShowCourtForm] = useState(false);

  useEffect(() => {
    const fetchCourts = async () => {
      try {
        const response = await axios.get(`/Courts/bysportcenterid?id=${sportCenter.id}`);
        setCourts(response.data);
      } catch (error) {
        console.error('Erro ao carregar as quadras', error);
      }
    };
    fetchCourts();
  }, [sportCenter.id]);

  const handleCreateOrUpdateCourt = async (court) => {
    try {
      if (court.id) {
        await axios.post(`/Courts/update`, court);
        setCourts((prev) => prev.map((c) => (c.id === court.id ? court : c)));
      } else {
        const response = await axios.post(`/Courts/newcourt`, court);
        setCourts((prev) => [...prev, response.data]);
      }
      setShowCourtForm(false);
      setSelectedCourt(null);
    } catch (error) {
      console.error('Erro ao salvar a quadra', error);
    }
  };

  const handleDeleteCourt = async (id) => {
    try {
      await axios.post(`/Courts/delete`,id);
      setCourts(courts.filter(c => c.id !== id));
    } catch (error) {
      console.error('Erro ao deletar a quadra', error);
    }
  };

  const handleEditCourt = (court) => {
    setSelectedCourt(court);
    setShowCourtForm(true);
  };

  return (
    <div className="admin-page">
      <h2>Gerenciamento de Quadras</h2>
      <button onClick={() => setShowCourtForm(true)}>Adicionar Quadra</button>
      {courts.map((court) => (
        <div className="item-card" key={court.id}>
          <h3>{court.name}</h3>		  
          <div>
            <button onClick={() => handleEditCourt(court)}>Edit</button>
            <button onClick={() => handleDeleteCourt(court.id)}>Delete</button>
          </div>
        </div>
      ))}
      {showCourtForm && (
        <CourtForm
          court={selectedCourt}
          sportCenterId={sportCenter.id}  // Passa o sportCenterId para o formulário
          onSave={handleCreateOrUpdateCourt}
          onClose={() => setShowCourtForm(false)}
        />
      )}
    </div>
  );
};

export default SelectCourt;
