import React, { useState, useEffect, useRef } from 'react';
import './AddSportCenterList.css';
import axios from '../../axiosConfig';

const AddSportCenterList = ({ onSportCenterSelect, user }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredSportCenters, setFilteredSportCenters] = useState([]);
  const [initialLoad, setInitialLoad] = useState(false); // Flag para controlar o carregamento inicial
  const inputRef = useRef(null); // Ref para o campo de pesquisa
  const listRef = useRef(null);  // Ref para a lista de Sport Centers

  useEffect(() => {
    const fetchSportCenters = async (term) => {
      try {
        const url = term ? `/SportCenters/search?regex=${term}` : `/SportCenters/search`;
        const response = await axios.get(url);
        setFilteredSportCenters(response.data);
      } catch (error) {
        console.error('Erro ao carregar os Sport Centers', error);
      }
    };

    if (searchTerm) {
      const debounceTimeout = setTimeout(() => {
        fetchSportCenters(searchTerm);
      }, 300); // Pequeno delay para evitar requisições a cada tecla pressionada
      return () => clearTimeout(debounceTimeout);
    } else if (!initialLoad) {
      fetchSportCenters(); // Recarrega os 10 itens iniciais quando o searchTerm está vazio
      setInitialLoad(true); // Marca como já carregado
    }
  }, [searchTerm, initialLoad]); // Verifica a mudança de searchTerm ou inicialização

  const handleBlur = (e) => {
    if (
      listRef.current &&
      (listRef.current.contains(e.relatedTarget) || listRef.current.contains(document.activeElement))
    ) {
      return;
    }

    // Só limpa o searchTerm se ele não estiver vazio
    if (searchTerm) {
      setSearchTerm('');
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  return (
    <div className="select-sport-center-group">
      <input
        type="text"
        placeholder="Search Sport Centers"
        value={searchTerm}
        onChange={handleSearchChange}
        onBlur={handleBlur}
        ref={inputRef} // Ref para o campo de pesquisa
        className="search-input"
      />
      <div ref={listRef} className="sport-center-list"> {/* Ref para a lista */}
        {filteredSportCenters.map(sc => (
          <div 
            key={sc.id} 
            className="sport-center-item" 
            onClick={() => onSportCenterSelect(sc)}
            tabIndex={0} // Torna o item focável
          >
            {sc.name}
          </div>
        ))}
      </div>
   </div>
  );
};

export default AddSportCenterList;
