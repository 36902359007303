import React from 'react';
import AddSportCenterList from './AddSportCenterList';

const SportCentersList = ({  onSelect }) => {
  const handleAddSportCenter = (newSportCenter) => {
    console.log("Selecionou:");
    console.log({ newSportCenter });
    onSelect(newSportCenter);
    sessionStorage.setItem('selectedSportCenter', JSON.stringify(newSportCenter));
  };

  return (
    <div className="sport-centers-master-div">
      <div>
        <h1>Select a Sport Center</h1>
        <AddSportCenterList onSportCenterSelect={handleAddSportCenter} />
      </div>    	  	  
	  </div>
	   
  );
};

export default SportCentersList;
