import React, { useState, useEffect } from 'react';

const SportCenterForm = ({ sportCenter, onSave, onClose }) => {
  const [name, setName] = useState('');
  const [picture, setPicture] = useState('');

  useEffect(() => {
    if (sportCenter) {
      setName(sportCenter.name || '');
      setPicture(sportCenter.picture || '');
    }
  }, [sportCenter]);

  const handleSubmit = () => {
    const newSportCenter = {
      id: sportCenter?.id,
      name,
      picture,
    };
    onSave(newSportCenter);
  };

  return (
    <div className="form-container">
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Nome do Sport Center"
      />
      <input
        type="text"
        value={picture}
        onChange={(e) => setPicture(e.target.value)}
        placeholder="URL da Imagem"
      />
      <button onClick={handleSubmit}>Salvar</button>
      <button onClick={onClose}>Cancelar</button>
    </div>
  );
};

export default SportCenterForm;
