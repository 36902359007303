import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../axiosConfig';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import './Login.css'; 


function Login({ onLogin }) {	
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.message) {
      setMessage(location.state.message);
    }
  }, [location.state]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
const handleSubmit = async (e) => {
  e.preventDefault();
  
  try {
    const response = await axios.post('/account/login', formData);
    const result = response.data;
    
    if (result.success) {
      toast.success("You are logged in!");
      const token = result.token;
      localStorage.setItem('token', token);
      onLogin();
      navigate('/');
    } else {
      // Handle multiple messages in messagetoshow with bullet points
      const errorMessage = Array.isArray(result.messagetoshow)
        ? result.messagetoshow.map(msg => `• ${msg}`).join('\n') // Add bullet points and join with line breaks
        : result.messagetoshow; // If it's a single string, just display it

      setMessage(errorMessage);
    }
  } catch (error) {
    // Handle multiple error messages if error.response.data.messagetoshow is a list
    let errorMessage = 'Verify your credentials.';
    
    if (error.response) {
      if (error.response.data && typeof error.response.data === 'string') {
        errorMessage = error.response.data; // Case where the backend returns a string error
      } else if (Array.isArray(error.response.data?.messagetoshow)) {
        errorMessage = error.response.data.messagetoshow.map(msg => `• ${msg}`).join('\n'); // Add bullet points and join with line breaks
      } else if (error.response.data?.messagetoshow) {
        errorMessage = error.response.data.messagetoshow; // Case where the backend returns the expected DTO
      } else if (error.response.data.errors) {
        // If there is a list of validation errors, format them with bullet points
        errorMessage = Object.values(error.response.data.errors).flat().map(msg => `• ${msg}`).join('\n');
      } else if (error.response.data.title) {
        errorMessage = error.response.data.title; // Title of a generic validation issue
      }
    }

    setMessage(errorMessage);
  }
};

  const handleGoogleLoginSuccess = async (response) => {
    try {
      const tokenId = response.credential;
      const result = await axios.post('/account/external-login', {
        provider: 'Google',
        tokenId: tokenId,
      });
      toast.success("You are logged in!");
      const token = result.data.token;
      localStorage.setItem('token', token);
      onLogin();
      navigate('/');
    } catch (error) {
      console.error('Erro ao fazer login com Google:', error);
      setMessage('Erro ao fazer login com Google.');
    }
  };

  const handleGoogleLoginFailure = (response) => {
    console.error('Falha ao fazer login com Google:', response);
    setMessage('Falha ao fazer login com Google.');
  };

  return (
    <GoogleOAuthProvider clientId="436125180551-d6uaqpn61sib6hovf74oknpumt9ruvqm.apps.googleusercontent.com">
        <div className="login-container">          
          <form onSubmit={handleSubmit} className="login-form">
            <div className="login-input-group">
              <label>Your email address:</label>
              <input
                id="user-email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="login-input-group">
              <label>Your password:</label>
              <input
                id="user-senha"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>
            <button id="loggin-button-click"  type="submit" className="login-button">Log in</button>
			{message && (
<div className="error-message">
  {typeof message === 'string'
    ? message.split('\n').map((msg, index) => (
        <div key={index}>{msg}</div>
      ))
    : <div>{String(message)}</div> 
  }
</div>
)}
          <a href="/forgotpassword" className="forgot-password-link">Forgot password?</a>
          </form>
          <div className="social-wrapper">
            <div className="social-wrapper-title">
              <span>Or</span>
            </div>
            <div className="social-login">
              <GoogleLogin
                onSuccess={handleGoogleLoginSuccess}
                onError={handleGoogleLoginFailure}
                className="social-button google-button"
				  theme="outline"
  size="large"
  shape="pill"
  logo_alignment="center"
              />
            </div> 
          </div>
          

          <div className="login-footer">
            <span>Don't have an account? <a href="/register" className="register-link">Sign up</a></span>
          </div>
        </div>
    </GoogleOAuthProvider>
  );
}

export default Login;
