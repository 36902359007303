import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppWrapper from './components/AppWrapper';
import { jwtDecode } from 'jwt-decode';
import Image150 from './images/150.png';
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  // Função para processar o token, usando useCallback para memorizar e evitar recriação desnecessária
  const processToken = useCallback((token) => {
    if (token && token.split('.').length === 3) {
      try {
        const decodedToken = jwtDecode(token);
        setIsAuthenticated(true);
        console.log("setting user", { decodedToken });
        setUser({
          name: decodedToken.name,
          isAdmin: decodedToken.isAdmin === "yes",  // Corrigido == para ===
          avatarUrl: decodedToken.picture || Image150,
          id: decodedToken.id,
        });
      } catch (error) {
        console.error('Erro ao decodificar o token:', error);
        setIsAuthenticated(false);
        localStorage.removeItem('token');
      }
    } else {
      setIsAuthenticated(false);
      localStorage.removeItem('token');
    }
  }, []);  // useCallback memoriza a função, evitando recriações

  // Carregar token ao montar o componente
  useEffect(() => {
    console.log("Initial load");
    const token = localStorage.getItem('token');
    if (token) {
      processToken(token);  // Processa o token uma única vez
    }
  }, [processToken]);  // processToken está memoizado pelo useCallback, então não criará loops

  const handleLogin = () => {
	console.log("chamou handle login")
    const token = localStorage.getItem('token');
    processToken(token);  // Usa a mesma função para processar o login
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUser(null);	
  };

  return (
    <Router>
      <AppWrapper
        isAuthenticated={isAuthenticated}
        handleLogin={handleLogin}		
        handleLogout={handleLogout}
        user={user}
      />
    </Router>
  );
}

export default App;
