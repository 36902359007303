import React, { useState } from 'react';
import axios from '../axiosConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import './ResetPassword.css';

function ResetPassword() {
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  // Extract token and email from the URL query params
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const email = queryParams.get('email');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setMessage('Passwords do not match!');
      return;
    }

    try {
      const response = await axios.post('/account/resetpassword', {
        email: email,
        token: token,
        password: formData.password,
      });

      const result = response.data;
      if (result.success) {
        setMessage(result.messagetoshow);
        navigate('/login', { state: { message: result.messagetoshow } });
      } else {
        setMessage(result.messagetoshow);
      }
    } catch (error) {
      let errorMessage = 'Please try again.';
      if (error.response) {
        if (Array.isArray(error.response.data?.messagetoshow)) {
          errorMessage = error.response.data.messagetoshow.join('\n');
        } else if (error.response.data?.messagetoshow) {
          errorMessage = error.response.data.messagetoshow;
        } else if (error.response.data.errors) {
          errorMessage = Object.values(error.response.data.errors).flat().join('; ');
        } else if (error.response.data.title) {
          errorMessage = error.response.data.title;
        }
      }
      setMessage('Error: ' + errorMessage);
    }
  };

  return (
    <div className="reset-password-container">
      <form onSubmit={handleSubmit} className="reset-password-form">
        <div className="reset-password-input-group">
          <label>New Password:</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <div className="reset-password-input-group">
          <label>Confirm Password:</label>
          <input
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="reset-password-button">Reset Password</button>
      </form>

      {message && <p className="reset-password-message">{message}</p>}
    </div>
  );
}

export default ResetPassword;
