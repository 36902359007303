import React, { useEffect, useRef } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { App } from '@capacitor/app'; // Correct import for Capacitor App listener
import VideosPage from './videospage/VideosPage';
import ProfilePage from './Profile';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import Register from './Register';
import CombinedPage from './CombinedPage';
import ProtectedRoute from './ProtectedRoute';
import SportCenterAdminPage from './admin/SportCenterAdminPage';
import StatusPage from './admin/StatusPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Capacitor } from '@capacitor/core';
import PrivacyPolicy from './PrivacyPolicy';

const GetDeviceInfo = () => {
  let userAgent = window.navigator.userAgent.toLowerCase();
  let isNative = Capacitor.isNativePlatform();
  let isMobile = false;
  let platform = Capacitor.getPlatform();

  if (!isNative) {
    const matched = userAgent.match(/android|iphone|ipad|ipod/);
    if (matched) {
      isMobile = true;
    }
  }
  
  

  return {
    userAgent,
    isNative,
    isMobile,
    platform
  };
};




const isSmallMenu = (isItNative, isItMobile) => {
  console.log('************ TisSmallMenu:', isItNative);
  if (isItNative || isItMobile) {
    document.documentElement.style.setProperty('--footer-height', '40px');
    document.documentElement.style.setProperty('--header-padding', '20px');
    return true;
  } else {
    document.documentElement.style.setProperty('--footer-height', '100px');
    document.documentElement.style.setProperty('--header-padding', '0px');
    return false;
  }
};

const AppWrapper = ({ isAuthenticated, handleLogin, handleLogout, user }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const mainContentRef = useRef(null);

  const DeviceInfo = GetDeviceInfo();
  const smallMenu = isSmallMenu(DeviceInfo.isNative, DeviceInfo.isMobile);

  console.log("********* Device Info: ", JSON.stringify(DeviceInfo));
  console.log("********* is Small Menu", smallMenu);


  const handleBackButton = (location, navigate) => {
    console.log("Android Back button pressed", { location });

    // Se estiver na página raiz ('/'), sair do app ou chamar o back do VideosPage
    if (location.pathname !== "/") {

      // Caso contrário, navega de volta
      console.log("Navigating back in history");
      navigate(-1); // Isso aciona window.history.back()
    }
    else {
      console.log("IGNOREANDO O listener back do WRAPER");
    }
  };

  // Back button listener for Android
  useEffect(() => {
    console.log("****************** ENTERED USEEFFECT *******************************");

    let backButtonListener

    const addBackButtonListener = async () => {
        if (DeviceInfo.isNative && DeviceInfo.platform === "android") {
            console.log("****************** LISTENER FOR BACK BUTTON *******************************");
            
            // Await the listener to avoid the deprecation warning
            backButtonListener = await App.addListener('backButton', () => {
                console.log("Back button pressed LOGINHINO");
                handleBackButton(location, navigate);
                return false; // Prevent the default behavior of exiting the app
            });
            
            // Cleanup function to remove the listener
            return () => {
                if (backButtonListener) {
					console.log("Cleanup listener1");
                    backButtonListener.remove();
                }
            };
        }
    };

    addBackButtonListener();
	
	
    // Cleanup effect on component unmount
    return () => {
 
        if (backButtonListener) {
          console.log("Cleanup listener2");
          backButtonListener.remove();
        }
    };

}, [DeviceInfo.isNative, DeviceInfo.platform, location, navigate]);

  return (
    <div className="app-wrapper">
      <Header isAuthenticated={isAuthenticated} onLogout={handleLogout} user={user} smallMenu={smallMenu} />
      <div className="App-main">
        <main ref={mainContentRef}>
          <ToastContainer
            position="top-center"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />

          <Routes>
            <Route path="/" element={<VideosPage isNative={DeviceInfo.isNative} isMobile={DeviceInfo.isMobile} />} />
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
            <Route path="/profile" element={<ProfilePage user={user} onLogout={handleLogout} />} />
            <Route path="/register" element={<Register onLogin={handleLogin} />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/about-contact" element={<CombinedPage isNative={DeviceInfo.isNative}  />} />
			<Route path="/privacy" element={<PrivacyPolicy />} />

            {isAuthenticated && (
              <>
                <Route path="/admin/SportCenterAdminPage" element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <SportCenterAdminPage />
                  </ProtectedRoute>
                } />
                <Route path="/admin/StatusPage" element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <StatusPage DeviceInfo={DeviceInfo} />
                  </ProtectedRoute>
                } />
              </>
            )}
          </Routes>
        </main>
      </div>
      <Footer isAuthenticated={isAuthenticated} smallMenu={smallMenu} user={user} />
    </div>
  );
};

export default AppWrapper;
