import React, { useEffect, useState } from 'react';
import './StatusPage.css';
import axios from '../../axiosConfig';
import TemperatureChartPopup from './TemperatureChartPopup';


const StatusPage = ({DeviceInfo}) => {
  const [equipments, setEquipments] = useState([]);
  const [endpoints, setEndpoints] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
    

  useEffect(() => {	  
	
    const fetchStatus = async () => {
      try {
        const response = await axios.get(`/Heartbeat/system_status`);
		
        const equip = response.data;
		console.error('TheLog', equip);
        setEquipments(equip);
      } catch (error) {
        console.error('Erro ao carregar os Sport Centers', error);
      }
    };

    const fetchEndpoints = async () => {
      const obj = {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        environment: process.env.REACT_APP_API_BASE_ENVIRONMENT,
        version: process.env.REACT_APP_VERSION,
        versionDate: process.env.REACT_APP_VERSION_DATA,
      };
      setEndpoints(obj);
    };


    fetchStatus();
    fetchEndpoints();    
  }, []);

  const handleTemperatureClick = async (equipmentId) => {
    try {
      const response = await axios.get(`/Heartbeat/temperature_history`, {
        params: { equipmentId }
      });
      const data = response.data;

      const labels = data.map(entry => new Date(entry.timestamp).toLocaleString());
      const temperatures = data.map(entry => entry.temperature);

      setChartData({
        labels: labels,
        datasets: [
          {
            label: 'Temperature Over Time',
            data: temperatures,
            fill: false,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            tension: 0.1,
          },
        ],
      });

      setShowPopup(true);
    } catch (error) {
      console.error('Error fetching temperature history', error);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="status-page">
      <h1>Monitoramento de Equipamentos</h1>
      <div className="equipment-list">
        {equipments.map((equipment, index) => (
          <div 
            className="equipment-card" 
            key={index} 
            style={{ backgroundColor: equipment.statusColor }} // Apply background color based on statusColor
          >
            <p><strong>Status:</strong> {equipment.status}</p> {/* Display the status */}
            <h2>Equipment: {equipment?.name}</h2>
            <h2>Sport Center: {equipment.sportCenter.name}</h2>
            <p><strong>MAC:</strong> {equipment.mac}</p>
            <p><strong>Machine ID:</strong> {equipment.machineId}</p>
            <p><strong>Último vídeo:</strong> {new Date(equipment.lastUploadedVideo).toLocaleString()}</p>
            {equipment.last10HB.length > 0 && (
              <div>
                <h3>Últimos Heartbeats:</h3>
                <ul>
                  {equipment.last10HB.map((hb, hbIndex) => (
                    <li key={hbIndex}>
                      <strong>Timestamp:</strong> {new Date(hb.last_hb).toLocaleString('pt-BR')} <br />

                      <strong>CPU:</strong> {hb.cpu_usage}% <br />
                      <strong>Memória:</strong> {hb.memory_usage}% <br />
                      <strong>
                        Temperatura:
                        <span 
                          style={{ color: 'blue', cursor: 'pointer' }} 
                          onClick={() => handleTemperatureClick(equipment.id)}
                        >
                          {hb.temperature}°C
                        </span>
                      </strong> <br />
                      <strong>Disco:</strong> {hb.disk_usage}%<br />
                      <strong>IP:</strong> {hb.ipaddress}<br />
                    </li>
                  ))}
                </ul>
                <h4>Estatísticas dos últimos 10 heartbeats:</h4>
                <p><strong>Máx Temperatura:</strong> {equipment.maxTemperature}°C</p>
                <p><strong>Mín Temperatura:</strong> {equipment.minTemperature}°C</p>
                <p><strong>Média Temperatura:</strong> {equipment.avgTemperature.toFixed(2)}°C</p>

                <p><strong>Máx CPU:</strong> {equipment.maxCpuUsage}%</p>
                <p><strong>Mín CPU:</strong> {equipment.minCpuUsage}%</p>
                <p><strong>Média CPU:</strong> {equipment.avgCpuUsage.toFixed(2)}%</p>

                <p><strong>Máx Memória:</strong> {equipment.maxMemoryUsage}%</p>
                <p><strong>Mín Memória:</strong> {equipment.minMemoryUsage}%</p>
                <p><strong>Média Memória:</strong> {equipment.avgMemoryUsage.toFixed(2)}%</p>
				
                <p><strong>Máx Disk Usage:</strong> {equipment.maxDiskUsage}%</p>
                <p><strong>Mín Disk Usage:</strong> {equipment.minDiskUsage}%</p>
                <p><strong>Média Disk Usage:</strong> {equipment.avgDiskUsage.toFixed(2)}%</p>				
              </div>
            )}
          </div>
        ))}
      </div>
      <div>
        <h2>EndPoints:</h2>
        {endpoints.baseUrl && (
          <div><span>BaseUrl: {endpoints.baseUrl}</span></div>
        )}
        {endpoints.environment && (
          <div><span>Environment: {endpoints.environment}</span></div>
        )}
        {endpoints.version && (
          <div><span>Version: {endpoints.version}</span></div>
        )}
        {endpoints.versionDate && (
          <div><span>Version Date: {endpoints.versionDate}</span></div>
        )}
      </div>

      {/* Informações do dispositivo */}
      <div>
        <h2>Informações do Dispositivo</h2>
		   <pre style={{ backgroundColor: "#f4f4f4", padding: "10px", borderRadius: "5px" }}>
          {JSON.stringify(DeviceInfo,null,2)}
      </pre>
		
        
      </div>

      {/* Temperature Chart Popup  */}
      <TemperatureChartPopup show={showPopup} onClose={closePopup} data={chartData} />
    </div>
  );
};

export default StatusPage;
