import React, { useState, useEffect } from 'react';
import axios from '../../axiosConfig';
import './SelectCourt.css';

const SelectCourt = ({ sportCenter, onSelectCourt , updateKey }) => {
  const [courts, setCourts] = useState([]);

  useEffect(() => {
    const fetchCourts = async () => {
      try {
        const response = await axios.get(`/Courts/bysportcenterid?id=${sportCenter.id}`);
        setCourts(response.data);
      } catch (error) {
        console.error('Erro ao carregar as quadras', error);
      }
    };

    fetchCourts();
  }, [sportCenter.id,updateKey]);

  const handleCourtSelect = (court) => {
    onSelectCourt(court);
    sessionStorage.setItem('selectedCourt', JSON.stringify(court));
  };

  return (
    <div className="courts-grid">
	   <h2>Select the court</h2>
      {courts.map((court, index) => (
        <button key={index} className="court-button" onClick={() => handleCourtSelect(court)}>
          <div className="court-box-info">
            {court.name}
          </div>
        </button>
      ))}
    </div>
  );
};

export default SelectCourt;
