// src/components/Header.js
import React, { useState, useRef, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import logo from '../images/logoGold.png';
import logoSmall from '../images/logoGoldDeLado.png';
import NoUserFoto from '../images/150.png';
import './Header.css';

const Header = ({ isAuthenticated, onLogout, user , smallMenu }) => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigate = (path) => {
    setIsOpen(false);
    navigate(path, { state: { user } });
  };

  const handleLogoutClick = () => {
    setIsOpen(false);
    onLogout();
    navigate('/login');
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) && !event.target.closest('.hamburger-menu')) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
     
  

  return (
    <header className="header">
      <div className={`logo${smallMenu ? 'Native' : ''}`}>
        <RouterLink to="/">
		  
		  {smallMenu && (
             <img src={logoSmall} alt="Logo" />		  
		  )}
		  
		  {!smallMenu && (
             <img src={logo} alt="Logo" />		  
		  )}
		  
 		  


        </RouterLink>
      </div>
	  
	   {!smallMenu && (
        	  
      <nav ref={menuRef} className={`nav-menu ${isOpen ? 'open' : ''}`}>
        <div className="user-info">
          <img
            src={isAuthenticated ? user.avatarUrl : NoUserFoto}
            alt="Avatar"
            className="user-avatar"
          />
          {isAuthenticated ? (
            <>
              <button onClick={() => handleNavigate('/profile')}>Profile</button>
              <button onClick={handleLogoutClick}>Logout</button>
            </>
          ) : (
            <>
              <button onClick={() => handleNavigate('/login')}>Login</button>
              <button onClick={() => handleNavigate('/register')}>Register</button>
            </>
          )}
        </div>
        <button id="videosPageTab" onClick={() => handleNavigate('/')}>Videos</button>
        <button onClick={() => handleNavigate('/about-contact')}>About</button>        
		<button onClick={() => handleNavigate('/privacy')}>Privacy</button>
        {isAuthenticated && user.isAdmin && (
          <>
            <button onClick={() => handleNavigate('/admin/SportCenterAdminPage')}>CRUD SportCenter</button>
            <button onClick={() => handleNavigate('/admin/StatusPage')}>Status</button>
          </>
        )}
      </nav>
	  
	  )} 
	  
	   {!smallMenu && (
      <div className="hamburger-menu" onClick={toggleMenu}>	 
      &#9776;
      </div>	  
	   )}
  
    </header>
  );
};

export default Header;
