import axios from '../../axiosConfig';
import { Share } from '@capacitor/share';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';

// Função para solicitar permissões (somente Android)
const requestPermissions = async () => {
  if (Capacitor.getPlatform() === 'android') {
    const { publicStorage } = await Filesystem.requestPermissions();
    if (publicStorage !== 'granted') {
      throw new Error('Permissão de armazenamento não concedida');
    }
    console.log('Permissão concedida para armazenamento externo');
  }
};

// Função para baixar o vídeo para a memória (Blob)
const downloadVideoToMemory = async (videoFileKey, trimStart, trimEnd) => {
  try {
    const blob = await getData(videoFileKey, trimStart, trimEnd); // Obtém o Blob do vídeo
    console.log('Vídeo baixado e armazenado em memória.');
    return blob; // Retorna o Blob para ser salvo temporariamente
  } catch (error) {
    console.error('Erro ao baixar o vídeo para memória:', error);	
    throw error;
  }
};

// Função para salvar o Blob temporariamente no Cache (memória temporária)
const saveVideoToCache = async (videoBlob, videoId) => {
  try {
    const base64Data = await blobToBase64(videoBlob); // Converte o Blob para Base64
    const fileName = `video_${videoId}.mp4`;

    // Salva o arquivo temporariamente na pasta Cache
    const savedFile = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Cache, // Usar o Cache para armazenamento temporário
    });

    console.log('Vídeo salvo temporariamente no Cache:', savedFile.uri);
    return savedFile.uri; // Retorna o URI do arquivo temporário salvo
  } catch (error) {
    console.error('Erro ao salvar o vídeo no cache:', error);
	
    throw error;
  }
};

// Função para compartilhar o vídeo salvo no cache
const shareVideo = async (fileUri) => {
  try {
    await Share.share({
      title: 'Video',
      text: 'Confira este vídeo!',
      url: fileUri, // Compartilha o arquivo diretamente, usando o caminho salvo temporariamente
      dialogTitle: 'Compartilhar vídeo',
    });
    console.log('Vídeo compartilhado com sucesso.');
  } catch (error) {
    console.error('Erro ao compartilhar o vídeo:', error);
  }
};

// Função utilitária para converter Blob em Base64
const blobToBase64 = (blob) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onloadend = () => resolve(reader.result.split(',')[1]);
  reader.onerror = reject;
  reader.readAsDataURL(blob);
});

// Função para buscar os dados do vídeo (Blob)
async function getData(videoFileKey, trimStart, trimEnd) {
  const requestData = { videoUrl: videoFileKey, startTime: trimStart, endTime: trimEnd };
  const response = await axios.post(`/VideoServe/process-video`, requestData);
  const url = await response.data;
  const downloadResponse = await fetch(url);
  const blob = await downloadResponse.blob();
  return blob;
}

// Função para compartilhar o vídeo no Mobile (Android/iPhone)
const handleShareNative = async (videoId, videoFileKey, trimStart, trimEnd) => {
  try {
    await requestPermissions(); // Pedir permissões no Android, se necessário

    // Baixar o vídeo e armazenar em memória
    const videoBlob = await downloadVideoToMemory(videoFileKey, trimStart, trimEnd);

    // Salvar o vídeo temporariamente no Cache
    const fileUri = await saveVideoToCache(videoBlob, videoId);

    // Compartilhar o vídeo salvo no Cache
    await shareVideo(fileUri);

    // Opcional: Remover o arquivo temporário após o compartilhamento (se quiser limpar o cache manualmente)
    // await Filesystem.deleteFile({ path: fileUri });	

  } catch (error) {
    console.error('Erro ao compartilhar o vídeo no nativo:', error);	
  }
};

// Função para compartilhar no Web (não salva localmente, usa link)
const handleShareWeb = async (videoId, videoFileKey, trimStart, trimEnd) => {
  try {
    // Gera um link para o vídeo no backend
    const response = await axios.post(`/VideoServe/process-video`, {
      videoUrl: videoFileKey,
      startTime: trimStart,
      endTime: trimEnd,
    });
    const videoUrl = response.data;
    console.info('videoUrl:', JSON.stringify(videoUrl));
    // Compartilhar o link gerado
    await navigator.share({
      title: 'Video',
      text: 'Confira este vídeo!',
      url: videoUrl, // Compartilha um link em vez do blob
    });

  } catch (error) {
    console.error('Erro ao compartilhar no web:', JSON.stringify(error));	
  }
};


const handleDownloadNative =  async (videoId, videoFileKey, trimStart, trimEnd) => {
	  
    
  // if (platform === 'android') {
  //  // Android: Use custom plugin to open file picker
  //  try {
  //    const { uri } = await Plugins.FilePicker.saveFile();
  //    // Save your file to the chosen location with the uri provided
  //    console.log('File saved to:', uri);
  //  } catch (error) {
  //    console.error('Error saving file on Android:', error);
  //  }
  //}

	
};
// Função para compartilhar no Web (fazer download do vídeo)
const handleDownloadWeb = async (videoId, videoFileKey, trimStart, trimEnd) => {
  try {
    // Gera um link para o vídeo no backend
    const response = await axios.post(`/VideoServe/process-video`, {
      videoUrl: videoFileKey,
      startTime: trimStart,
      endTime: trimEnd,
    });
    
    const videoUrl = response.data;
    console.info('videoUrl:', JSON.stringify(videoUrl));

    // Faz o download do vídeo
    const downloadResponse = await fetch(videoUrl);
    const blob = await downloadResponse.blob();

    // Cria um link de download
    const downloadLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = `video_${videoId}.mp4`; // Nome do arquivo a ser baixado

    // Adiciona o link ao DOM, clica e remove depois
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    // Limpa o objeto URL
    URL.revokeObjectURL(url);
    
    console.log('Download iniciado com sucesso.');
  } catch (error) {
    console.error('Erro ao fazer o download no web:', error);
  }
};


export const handleDownload = async (videoId, videoFileKey, trimStart, trimEnd, isMobile, isNative) => {
  if (isNative) {
    // Android/iPhone nativo
    await handleDownloadNative(videoId, videoFileKey, trimStart, trimEnd);
  } else {
    // Web
    await handleDownloadWeb(videoId, videoFileKey, trimStart, trimEnd);
  }
};

// Função principal para decidir entre mobile nativo e web
export const handleShare = async (videoId, videoFileKey, trimStart, trimEnd, isMobile, isNative) => {
  if (isNative) {
    // Android/iPhone nativo
    await handleShareNative(videoId, videoFileKey, trimStart, trimEnd);
  } else {
    // Web
    await handleShareWeb(videoId, videoFileKey, trimStart, trimEnd);
  }
};
