import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from '../../axiosConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { App } from '@capacitor/app';
import MyLoading from './MyLoading';
import SelectCourt from './SelectCourt';
import CourtDetailsPage from './CourtDetailsPage';
import DateDetailsPage from './DateDetailsPage';
import SportCentersList from './SportCentersList';
import SelectedDetails from './SelectedDetails';
import VideoPlayer from './VideoPlayer';
import ImageNoVideo from '../../images/imagenovideo.svg';
import ImageThumb from '../../images/videothumbpng.png';
import aguardandoThumb from '../../images/videothumbpng2.png';
import './VideosPage.css';

const VideosPage = ({ isNative, isMobile }) => {

  const [selectedSportCenter, setSelectedSportCenter] = useState(() => {
    const savedSportCenter = sessionStorage.getItem('selectedSportCenter');
    return savedSportCenter ? JSON.parse(savedSportCenter) : null;
  });
  const [selectedCourt, setSelectedCourt] = useState(() => {
    const savedCourt = sessionStorage.getItem('selectedCourt');
    return savedCourt ? JSON.parse(savedCourt) : null;
  });
  const [selectedDate, setSelectedDate] = useState(() => {
    const savedDate = sessionStorage.getItem('selectedDate');
    return savedDate ? new Date(JSON.parse(savedDate)) : null;
  });
  const [selectedHour, setSelectedHour] = useState(() => {
    const savedHour = sessionStorage.getItem('selectedHour');
    return savedHour ? JSON.parse(savedHour) : null;
  });
  const [step, setStep] = useState(() => {
    const savedStep = sessionStorage.getItem('step');
    return savedStep ? JSON.parse(savedStep) : 0;
  });

  const [isLoaded, setIsLoaded] = useState(false);
  const [sections, setSections] = useState([]);
  const [currentVideo, setCurrentVideo] = useState(null);
  const videoRef = useRef(null);
  const initialLoadRef = useRef(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [updateKey, setUpdateKey] = useState(generateUniqueKey());
  const mainContentRef = useRef(null);
  const VideoListRef = useRef(null);
  const [canRefresh, setcanRefresh] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const startY = useRef(0);
  const pullDistance = useRef(0);
  const [pullingDistance, setPullingDistance] = useState(0);
  const [popupMaximized, setPopupMaximized] = useState(false);

  const [showHint, setShowHint] = useState(false);
  const [isHintVisible, setIsHintVisible] = useState(false);

  const [thumbnailUrls, setThumbnailUrls] = useState({});  // URLs dos thumbnails 
  const [isRenewing, setIsRenewing] = useState({});



  const handleHourSelect = useCallback(async (hour) => {
    setSelectedHour(hour);
    sessionStorage.setItem('selectedHour', JSON.stringify(hour));
    setStep(4); // All filters selected, videos should be loaded now

    if (selectedCourt && selectedSportCenter && selectedDate && hour) {
      try {
        const response = await axios.get(`/VideoServe/GetVideosByCourtAndDateTimeNew`, {
          params: {
            CourtId: selectedCourt.id,
            SportCenterId: selectedSportCenter.id,
            date: `${selectedDate.getFullYear()}-${("0" + (selectedDate.getMonth() + 1)).slice(-2)}-${("0" + selectedDate.getDate()).slice(-2)}`,
            hora: hour.hour,
          }
        });

        if (response.data && Array.isArray(response.data)) {
          setSections(response.data);
        } else {
          console.error('Unexpected data format:', response.data);
        }
      } catch (error) {
        console.error('Erro ao carregar os v�deos', error);
      }
    }
    setIsLoaded(true);
    setIsRefreshing(false);  // conclui o refresh
  }, [selectedCourt, selectedSportCenter, selectedDate]);

  const handleUpdate = useCallback(async () => {
    console.log("Update Called", { step });

    if (step === 4) {
      await handleHourSelect(selectedHour);
    } else {
      setUpdateKey(generateUniqueKey());
    }
  }, [step, selectedHour, handleHourSelect]);

  useEffect(() => {
    const loadThumbnails = async () => {
      if (sections != null && sections.length > 0) {
        for (const section of sections) {
          for (const video of section.videos) {
            for (const file of video.theFiles.filter(file => file.myS3FileType === 1)) {

              console.log(video);  // Mostra o ID e o arquivo de cada thumbnail
              const url = await renderThumbnailNew(video.id, file);  // Pega o thumbnail de cada vídeo
              setThumbnailUrls(prevState => ({
                ...prevState,
                [video.id]: {
                  ...prevState[video.id], // Preserve existing entries for this video
                  [file.id]: url,         // Set the new thumbnail URL for the file
                }
              }));
            }
          }
        }
      }
    };

    loadThumbnails();
    console.log("section section section section section ", { sections });

  }, [sections]);  // Reexecuta sempre que a lista de vídeos mudar

  useEffect(() => {
    console.log("loadThumbnails loadThumbnails loadThumbnails loadThumbnails", { thumbnailUrls });
  }, [thumbnailUrls]);  // Reexecuta sempre que a lista de vídeos mudar

  useEffect(() => {
    console.log("isRenewing isRenewing isRenewing isRenewing", { isRenewing });
  }, [isRenewing]);  // Reexecuta sempre que a lista de vídeos mudar


  useEffect(() => {
    if (isNative) {
      // Função para detectar a orientação da tela
      const detectOrientation = () => {
        console.log("chamou o : detectOrientation")
        const isLandscapeMode = window.matchMedia("(orientation: landscape)").matches;
        console.log("landscape setado para :", isLandscapeMode)

        // Maximiza automaticamente quando está em landscape e o popup está aberto      
        setPopupMaximized(isLandscapeMode && isPopupOpen);
      };

      // Verificar orientação inicial e adicionar o listener
      detectOrientation();
      window.addEventListener('resize', detectOrientation);

      // Limpeza ao desmontar o componente
      return () => {
        window.removeEventListener('resize', detectOrientation);
      };

    }
  }, [isPopupOpen, isNative]);

  useEffect(() => {
    console.log("popup open or closed")

    const canRef = isNative && !isPopupOpen;

    console.log("Setando Refresh para ", { canRef })

    setcanRefresh(canRef);
  }, [isNative, isPopupOpen]);


  useEffect(() => {
    sessionStorage.setItem('step', JSON.stringify(step));
  }, [step]);

  useEffect(() => {
    if (initialLoadRef.current) {
      initialLoadRef.current = false;
      if (selectedHour) {
        handleHourSelect(selectedHour);
      }
    }
    else {
      if (!selectedHour) {
        console.log("limpando Sections")
        setSections(null);
      }
    }

  }, [selectedHour, handleHourSelect]);

  useEffect(() => {
    if (!selectedSportCenter || !selectedCourt || !selectedDate || !selectedHour) {
      setCurrentVideo(null);
    }
  }, [selectedSportCenter, selectedCourt, selectedDate, selectedHour]);


  const handleRefresh = useCallback(async () => {
    console.log("entrou no handleRefresh");
    if (isRefreshing) return;

    setPullingDistance(100 * 0.5);
    setIsRefreshing(true);

    await handleUpdate();

    setTimeout(() => {
      setPullingDistance(0);
      setIsRefreshing(false);
    }, 500);
  }, [isRefreshing, handleUpdate]);


  // useEffect(() => {
  // const checkRefs = () => {
  // if (mainContentRef.current && VideoListRef.current) {
  // console.log('Both refs are populated');
  // } else {
  // console.log('Waiting for VideoListRef to populate...');
  // }
  // };

  // // Wait until refs are populated
  // checkRefs();
  // }, [mainContentRef.current, VideoListRef.current]); // Re-run when refs change

  useEffect(() => {


    const mainContent = mainContentRef.current;
    const VideoListContent = VideoListRef.current;

    if (!mainContent) {
      console.log('Waiting for refs to populate For Real...');
      return; // Exit if refs are not ready
    }

    console.log('Refs populated', { mainContent });

    if (!canRefresh) {
      console.log('Refresh disabled');
      return;
    }

    // Fun��o para detectar quando o toque come�a
    const handleTouchStart = (e) => {


      console.log("All good, non one is null");


      const isVideoListClicked = VideoListContent?.contains(e.target);

      console.log("handleTouchStart")
      console.log(mainContent.scrollTop)
      console.log(VideoListContent?.scrollTop)
      console.log()

      if (mainContent.scrollTop === 0) {
        if (!isVideoListClicked || VideoListContent.scrollTop === 0) {
          console.log("handleTouchStart -> scroltop0")

          startY.current = e.touches[0].clientY;  // Captura a posi��o inicial do toque
          pullDistance.current = 0;  // Reseta a dist�ncia puxada
          setPullingDistance(0);  // Reseta a dist�ncia visual
        }
      }
    };

    // Fun��o para detectar quando o usu�rio est� "puxando" para baixo
    const handleTouchMove = (e) => {

      const isVideoListClicked = VideoListContent?.contains(e.target);



      const currentY = e.touches[0].clientY;
      if (mainContent.scrollTop === 0 && (!isVideoListClicked || VideoListContent.scrollTop === 0)) {

        pullDistance.current = currentY - startY.current;  // Calcula a dist�ncia puxada
        console.log("move", { pullDistance })

        if (pullDistance.current > 200) {
          pullDistance.current = 200;
        }

        if (pullDistance.current > 0 && mainContent.scrollTop === 0 && (!isVideoListClicked || VideoListContent.scrollTop === 0)) {
          setPullingDistance(pullDistance.current * 0.5);  // Aplica um fator de resist�ncia visual
        }
      }
    };

    // Func para detectar quando o toque e solto
    const handleTouchEnd = (e) => {

      if (!canRefresh) {
        console.log("RegreshDesabilitado")
        return () => {
          console.log("RemoveOsHandlers", { canRefresh })
          // Remove os listeners para evitar vazamento de mem�ria
          mainContent.removeEventListener('touchstart', handleTouchStart);
          mainContent.removeEventListener('touchmove', handleTouchMove);
          mainContent.removeEventListener('touchend', handleTouchEnd);
        };
      }

      const isVideoListClicked = VideoListContent?.contains(e.target);
      console.log("handleTouchEndTestContains", { isVideoListClicked })


      const d = pullDistance.current;
      console.log("puling dist", { d })

      const a = VideoListContent.scrollTop;
      console.log("VideoListContent", { a })
      //if (pullDistance.current > 100 && mainContent.scrollTop === 0 && (VideoListContent.scrollTop === 0 || !isVideoListClicked) && !isRefreshing) {
      if (pullDistance.current > 100 && mainContent.scrollTop === 0 && (!isVideoListClicked || (isVideoListClicked && VideoListContent.scrollTop === 0)) && !isRefreshing) {
        handleRefresh();  // S� dispara o refresh se o usu�rio puxar mais de 100px e soltar
      } else {
        setPullingDistance(0);  // Reseta o movimento visual se o usu�rio devolver o scroll
      }
    };

    // Adiciona os listeners para os eventos de toque
    if (canRefresh) {

      console.log("AdicionaOsHandles", { canRefresh })
      mainContent.addEventListener('touchstart', handleTouchStart);
      mainContent.addEventListener('touchmove', handleTouchMove);
      mainContent.addEventListener('touchend', handleTouchEnd);
    }
    else { console.log("nao adicionando ja q ta false") }
    return () => {
      console.log("RemoveOsHandlers")
      // Remove os listeners para evitar vazamento de mem�ria
      mainContent.removeEventListener('touchstart', handleTouchStart);
      mainContent.removeEventListener('touchmove', handleTouchMove);
      mainContent.removeEventListener('touchend', handleTouchEnd);
    };


  }, [isRefreshing, step, canRefresh, isLoaded, handleRefresh]);

  // Fun��o para renovar URLs de v�deos
  const renewVideoUrls = async (id, fileKey) => {
    try {
      const response = await axios.post('/VideoServe/RenewUrl', {
        id: id,
        fileKey: fileKey,
      });

      console.log("RETORNOU")
      if (response.data) {
        console.log("RETORNOU E EXISTE DATA")
        console.log(response.data)
        return {
          url: response.data.url
        };
      }
    } catch (error) {
      console.error('Erro ao renovar as URLs', error);
    }

    console.log("RETORNOU NUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUL")
    return null;
  };


  const onCancelCurrent = async () => {
    setCurrentVideo(null);
    setIsPopupOpen(false); // Fechar o popup
  };

  const handleSelectCourt = (court) => {
    setSelectedCourt(court);
    setSelectedDate(null);
    setSelectedHour(null);
    sessionStorage.setItem('selectedCourt', JSON.stringify(court));
    setStep(2); // Move to the date selection step
  };

  const handleSelectDate = (date) => {
    setSelectedDate(date);
    setSelectedHour(null);
    sessionStorage.setItem('selectedDate', JSON.stringify(date));
    setStep(3); // Move to the hour selection step
  };



  const formattimestamp = (datetime) => {
    const date = new Date(datetime);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };




  const HandleMaximize = () => {

    if (isHintVisible) return;

    setIsHintVisible(true);
    setShowHint(true);


    const hintTimeout = setTimeout(() => {
      setShowHint(false);
      setIsHintVisible(false);
    }, 2000);

    return () => clearTimeout(hintTimeout);
  };

  const handleVideoSelect = async (videoInput) => {
    console.log("handleVideoSelect")
    console.log(videoInput)

    // Find the first file where myS3FileType === 0 (assumed to be video)
    const video = videoInput.theFiles.find(file => file.myS3FileType === 0);

    console.log("handleVideoSelect Selected")
    console.log(video)

    if (!video) {
      return null;
    }

    if (video.urlExpired) {
      const renewedUrls = await renewVideoUrls(video.id, video.file_key);

      if (renewedUrls) {
        video.url = renewedUrls.url;
        video.urlExpired = false;
      } else {
        console.error('Nao foi possivel renovar as URLs');
        return null;
      }
    }

    //Load all thumbnails of the video	

    console.log("VideosAntes")
    console.log(videoInput)
    await renderThumbnail(videoInput, false);
    console.log("Depois")
    console.log(videoInput)



    setCurrentVideo(videoInput);
    setIsPopupOpen(true); // Abrir o popup    
  };

  const handleBack = useCallback(() => {
    console.log("handleBack called. Current step:", step);
    if (step === 4) {
      setSelectedHour(null);
      sessionStorage.removeItem('selectedHour');
    } else if (step === 3) {
      setSelectedDate(null);
      sessionStorage.removeItem('selectedDate');
    } else if (step === 2) {
      setSelectedCourt(null);
      sessionStorage.removeItem('selectedCourt');
    } else if (step === 1) {
      setSelectedSportCenter(null);
      sessionStorage.removeItem('selectedSportCenter');
    }

    if (step > 0) {
      setStep(step - 1); // Move back one step
    }
  }, [step]);

  useEffect(() => {
    let backButtonListener;

    const addBackButtonListener = async () => {
      backButtonListener = await App.addListener('backButton', () => {
        console.log("Back button pressed in VideosPage", step);

        if (isPopupOpen) {
          onCancelCurrent();
        } else if (step > 0) {
          handleBack();
        } else {
          console.log("User is on the home page, exiting app");
          App.exitApp(); // Close the app
        }
      });
    };

    // Add the listener when component mounts or step changes
    console.log("Adding Back Button Listener, STEP=", step);
    addBackButtonListener();

    // Cleanup listener on component unmount or before adding a new one
    return () => {
      console.log("Cleanup Back Button Listener, STEP=", step);
      if (backButtonListener) {
        backButtonListener.remove();
      }
    };
  }, [step, isPopupOpen, handleBack]);  // Dependencies to re-run when step or popup state changes


  const renderThumbnailNew = async (MetadataId, S3File) => {
    console.log("NEW STUFF", { MetadataId }, { S3File })
    return await renderItSelf(MetadataId, S3File);
  };

  const renderThumbnail = async (videoInput, OnlyFirst) => {
    let video = null;
    console.log("achando as thumbs COM ");

    // Caso OnlyFirst seja verdadeiro, pegar apenas o primeiro thumbnail
    if (OnlyFirst) {
      video = videoInput.theFiles.find(file => file.myS3FileType === 1);  // Pega o primeiro thumbnail
      console.log("OOOOOOOOOONLY FIRST ");
      console.log("thumbs:", video);

      // Chama a função de renderização
      return await renderItSelf(videoInput.id, video);
    } else {
      console.log("AAAAAAAAAAAAAALLLL ");

      // Pega todos os thumbnails
      const videos = videoInput.theFiles.filter(file => file.myS3FileType === 1);

      // Executa a renderização para cada thumbnail usando Promise.all para garantir paralelismo
      await Promise.all(
        videos.map(async (video) => {
          await renderItSelf(videoInput.id, video);  // Chama a função de renderização para cada um
        })
      );
    }
  };



  const renderItSelf = async (id, video) => {
    if (!video) {
      return ImageThumb;  // Se não achar thumbnail, retorna uma imagem padrão
    }

    if (video.urlExpired) {
      console.log("Thumbnail URL expirado, renovando...");

      // Mark as "renewing" for a specific video and thumbnail
      setIsRenewing(prevState => ({
        ...prevState,
        [video.id]: {
          ...prevState[video.id],  // Ensure the previous state for this `id` is kept
          [id]: true,  // Set the renewing flag for the specific video
        }
      }));

      // Chama a função de renovação da URL
      const renewedUrls = await renewVideoUrls(video.id, video.file_key);

      if (renewedUrls) {
        // Atualiza o estado de thumbnails com a nova URL renovada
        setThumbnailUrls(prevState => ({
          ...prevState,
          [video.id]: {
            ...prevState[id],  // Ensure the previous thumbnails for this video are kept
            [id]: renewedUrls.url,          // Update the thumbnail for the specific file
          }  // Atualiza a URL deste vídeo
        }));
      } else {
        console.error('Não foi possível renovar as URLs');
      }

      // Após a renovação, remove o estado de "aguardando"
      setIsRenewing(prevState => ({
        ...prevState,
        [video.id]: {
          ...prevState[id],  // Ensure the previous state for this `id` is kept
          [id]: true,  // Set the renewing flag for the specific video
        }
      }));

      return renewedUrls ? renewedUrls.url : ImageThumb;  // Retorna a nova URL ou imagem padrão
    }


    return video.url || ImageThumb;  // Retorna a URL atual se não estiver expirada
  };


  function generateUniqueKey() {
    return `${Date.now()}-${Math.floor(Math.random() * 10000)}`;
  }

  return (
    <React.Fragment>
      {currentVideo && isPopupOpen && (
        <div className={`video-popup${popupMaximized ? '-max' : ''}`}>

          <VideoPlayer
            videoMetadata={currentVideo}
            videoRef={videoRef}
            setIsPopupOpen={setIsPopupOpen}
            isNative={isNative}
            isMobile={isMobile}
            popupMaximized={popupMaximized}
            showHint={showHint}
            HandleMaximize={HandleMaximize}
            thumbnails={thumbnailUrls[currentVideo.id]}
          />
        </div>
      )}

      {pullingDistance > 5 && (  // Condiciona a visibilidade do refresh-indicator
        <div
          className={`refresh-indicator ${isRefreshing ? 'rotating' : ''}`}
          style={{ transform: `rotate(${pullingDistance * 1.5}deg)` }}
        >
          <FontAwesomeIcon icon={faSyncAlt} />
        </div>
      )}
      <div
        className={`videos-page ${pullingDistance === 0 ? '' : 'no-animation'}`}
        ref={mainContentRef}
        style={{
          transform: `translateY(${pullingDistance}px)`,  // Ainda controlado via JS
        }}
      >
        <div className="filters-container">
          {!selectedSportCenter ? (
            <SportCentersList
              onSelect={(sportCenter) => {
                setSelectedSportCenter(sportCenter);
                sessionStorage.setItem('selectedSportCenter', JSON.stringify(sportCenter));
                setStep(1); // Move to court selection step
              }}
            />
          ) : (
            <SelectedDetails
              selectedSportCenter={selectedSportCenter}
              selectedCourt={selectedCourt}
              selectedDate={selectedDate}
              selectedHour={selectedHour}
              isNative={isNative}
              isMobile={isMobile}
              stage={step}
              onBackToSportCenter={() => {
                setSelectedHour(null);
                setSelectedDate(null);
                setSelectedCourt(null);
                setSelectedSportCenter(null);
                sessionStorage.removeItem('selectedSportCenter');
                sessionStorage.removeItem('selectedCourt');
                sessionStorage.removeItem('selectedDate');
                sessionStorage.removeItem('selectedHour');
                console.log("Back to SC. Step:", step);
                setStep(0);
              }}
              onBackToCourt={() => {
                setSelectedCourt(null);
                setSelectedDate(null);
                setSelectedHour(null);
                sessionStorage.removeItem('selectedCourt');
                sessionStorage.removeItem('selectedDate');
                sessionStorage.removeItem('selectedHour');
                setStep(1);
                console.log("Back to Court. Step:", step);
              }}
              onBackToDate={() => {
                setSelectedDate(null);
                setSelectedHour(null);
                setSections([]);
                sessionStorage.removeItem('selectedDate');
                sessionStorage.removeItem('selectedHour');
                setStep(2);
                console.log("Back to Date. Step:", step);
              }}
              onBackToHour={() => {
                setSelectedHour(null);
                setSections([]);
                sessionStorage.removeItem('selectedHour');
                setStep(3);
                console.log("Back to Hour. Step:", step);
              }}
              OnhandleBack={handleBack}
              OnhandleUpdate={handleUpdate}
            />
          )}

          {!selectedCourt && selectedSportCenter && (
            <SelectCourt sportCenter={selectedSportCenter} onSelectCourt={handleSelectCourt} updateKey={updateKey} />
          )}

          {selectedCourt && !selectedDate && (
            <CourtDetailsPage court={selectedCourt} onDateSelect={handleSelectDate} updateKey={updateKey} />
          )}

          {selectedDate && !selectedHour && (
            <DateDetailsPage court={selectedCourt} date={selectedDate} onHourSelect={handleHourSelect} updateKey={updateKey} />
          )}
        </div>

        {isLoaded ? (

          (selectedHour ? (
            sections?.length > 0 ? (
              <>


                <div className="main-content" ref={VideoListRef} >
                  {sections.map((section) => (
                    <div key={section.sectionIdentifier}>
                      <h2 className="section-header">
                        <AiOutlineClockCircle size={20} />
                        {formattimestamp(section.timestamp)}
                      </h2>

                      <div className="newDivInsteadOfSwiper">
                        {section.videos.map((video) => (
                          <div className="DivInsteadOfSwiperSlide" key={video.id}>
                            <img
                              src={
                                isRenewing[video.id]?.[video.theFiles.find(file => file.myS3FileType === 1)?.id]  // Safely check if this file is renewing
                                  ? aguardandoThumb  // If renewing, show the waiting thumbnail
                                  : (thumbnailUrls[video.id]?.[video.theFiles.find(file => file.myS3FileType === 1)?.id] || ImageThumb)  // Safely get the thumbnail URL or fallback
                              }
                              id={`thumb${video.id}-${video.theFiles.find(file => file.myS3FileType === 1)?.id}`}  // Set the correct ID for the image
                              alt="Video Thumbnail"
                              className={`thumbnail ${currentVideo?.id === video.id ? 'selected-video' : ''}`}
                              onClick={video.videocomplete ? () => handleVideoSelect(video) : undefined}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <MyLoading />
            )
          ) : null)) : null}
      </div>
    </React.Fragment>
  );
};

export default VideosPage;

