import React from 'react';
import './About.css';
import banner from '../images/banner1.png';

const About = ({isNative}) => {
  return (
    <section id="about" className="section">
      <h1>About Us</h1>
      <p>
        At <strong>SportsPlayback</strong>, we specialize in capturing and preserving the most exciting moments in sports. 
        Our mission is to ensure that athletes, whether amateurs or professionals, never miss the chance to relive their standout performances. 
        Using advanced technology, we provide a seamless way to record, view, and share highlights from courts equipped with our system.
      </p>
      <img src={banner} alt="About us" className="about-image" />
      <p>
        With just a single press, players can instantly save key moments — from game-winning shots to brilliant assists. These moments are stored in our app, where users can easily access and share them with friends, teammates, or on social media.
      </p>
      <p>
        We are committed to enhancing the sports experience by allowing athletes to reflect on their performances, celebrate their best plays, and showcase their skills. With seamless integration on partnered courts and a user-friendly app, SportsPlayback ensures that every game can be remembered and shared.
      </p>
      <p>Join us as we revolutionize the way athletes capture their moments, one highlight at a time.</p>

     
      {!isNative && (
      <div className="download-section">
        <h2>Download the App</h2>
        <p>Get our app to relive your greatest moments on the go:</p>
        <div className="app-links">
          <a href="https://play.google.com/store/apps/details?id=com.sportsplayback.app" target="_blank" rel="noopener noreferrer">
            <img src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg" alt="Download on Google Play" className="app-badge"/>
          </a>
          <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
            <img src="https://upload.wikimedia.org/wikipedia/commons/6/67/App_Store_badge_EN.svg" alt="Download on the App Store" className="app-badge"/>
          </a>
        </div>
      </div>
	  
	  )}
    </section>
  );
};

export default About;