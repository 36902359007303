import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1>Privacy Policy</h1>
      <p>Last updated: 21/09/2024</p>

      <h2>Introduction</h2>
      <p>
        This Privacy Policy explains how we collect, use, and protect your personal data when you use our services, including our website and mobile app. We are committed to complying with GDPR regulations and ensuring your privacy is protected.
      </p>

      <h2>Data We Collect</h2>
      <p>
        We may collect the following personal data when you register or interact with our services:
      </p>
      <ul>
        <li><strong>Name and email address</strong>: for account creation and login.</li>
        <li><strong>Login information</strong>: for authentication and access to your account.</li>
        <li><strong>App usage data</strong>: for improving the user experience.</li>
      </ul>
      <p>We do not collect any sensitive personal data.</p>

      <h2>How We Use Your Data</h2>
      <p>We use your personal data to:</p>
      <ul>
        <li>Authenticate your login and give you access to the app.</li>
        <li>Communicate with you regarding your account and provide customer support.</li>
        <li>Analyze usage of the app to improve performance and features.</li>
      </ul>

      <h2>Sharing of Data</h2>
      <p>
        We do not sell or share your personal data with third parties, except for service providers that help us operate the app (e.g., cloud storage providers), and they are required to protect your data according to GDPR standards.
      </p>

      <h2>Data Security</h2>
      <p>
        We take reasonable steps to protect your data using encryption, HTTPS, and secure storage methods. Passwords are hashed and not stored in plain text.
      </p>

      <h2>Data Retention</h2>
      <p>
        We will retain your personal data for as long as your account is active or as needed to provide services. If you wish to delete your data, please contact us, and we will comply with your request in accordance with GDPR.
      </p>

      <h2>Your Rights</h2>
      <p>Under GDPR, you have the following rights:</p>
      <ul>
        <li><strong>Access</strong>: You can request access to the personal data we hold about you.</li>
        <li><strong>Correction</strong>: You can request corrections to any incorrect or outdated information.</li>
        <li><strong>Deletion</strong>: You can request the deletion of your personal data.</li>
        <li><strong>Objection</strong>: You can object to the processing of your data.</li>
      </ul>

      <h2>Cookies</h2>
      <p>
        Our website uses cookies to improve your experience and provide analytics. By using the site, you consent to the use of cookies. You can manage cookie preferences in your browser.
      </p>

      <h2>Contact Us</h2>
      <p>If you have any questions about this Privacy Policy or wish to exercise your rights, you can contact us at:</p>
      <ul>
        <li><strong>Email</strong>: <a href="mailto:contact@sportsplayback.com">contact@sportsplayback.com</a></li>        
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
