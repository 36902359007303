import './MyLoading.css';

const MyLoading = () => {
  return (
    <div className="my-loading">
      <div className="spinner"></div>      
    </div>
  );
}

export default MyLoading;
