import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <section id="contact" className="section">
      <h1>Contact Us</h1>
      <p>
        We’d love to hear from you! Whether you have a question about our services, need support, or want to provide feedback, you can reach us through the following ways:
      </p>

      <ul className="contact-list">
        <li><strong>Email:</strong> contact@sportsplayback.com</li>
        <li><strong>Phone:</strong> +353 83 073 9264</li>
        
        <li><strong>Social Media:</strong> Follow us on 
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"> Facebook</a>, 
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"> Twitter</a>, and 
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"> Instagram</a>
        </li>
      </ul>

      <p>We aim to respond to all inquiries within 24-48 hours. Thank you for reaching out!</p>
    </section>
  );
};

export default Contact;
