import React, { useState, useEffect } from 'react';
import axios from '../../axiosConfig';
import SportCenterForm from './SportCenterForm';
import SelectCourt from './SelectCourt';

const SportCenterAdminPage = () => {
  const [sportCenters, setSportCenters] = useState([]);
  const [selectedSportCenter, setSelectedSportCenter] = useState(null);
  const [showSportCenterForm, setShowSportCenterForm] = useState(false);

  useEffect(() => {
    const fetchSportCenters = async () => {
      try {
        const response = await axios.get('/SportCenters/getall');
        setSportCenters(response.data);
      } catch (error) {
        console.error('Erro ao carregar os Sport Centers', error);
      }
    };
    fetchSportCenters();
  }, []);

  const handleCreateOrUpdateSportCenter = async (sportCenter) => {
    try {
      if (sportCenter.id) {
        await axios.post(`/SportCenters/update`, sportCenter);
        setSportCenters((prev) => prev.map((sc) => (sc.id === sportCenter.id ? sportCenter : sc)));
      } else {
        const response = await axios.post('/SportCenters/newsportcenter', sportCenter);
        setSportCenters((prev) => [...prev, response.data]);
      }
      setShowSportCenterForm(false);
      setSelectedSportCenter(null);
    } catch (error) {
      console.error('Erro ao salvar o Sport Center', error);
    }
  };

  const handleDeleteSportCenter = async (id) => {
    try {
      await axios.post(`/SportCenters/delete`,id);
      setSportCenters(sportCenters.filter(sc => sc.id !== id));
    } catch (error) {
      console.error('Erro ao deletar o Sport Center', error);
    }
  };

  const handleEditSportCenter = (sportCenter) => {
    setSelectedSportCenter(sportCenter);
    setShowSportCenterForm(true);
  };

  return (
    <div className="admin-page">
      <h1>Gerenciamento de Sport Centers</h1>
      <button onClick={() => { setSelectedSportCenter(null); setShowSportCenterForm(true); }}>
        Adicionar Sport Center
      </button>
      {sportCenters?.map((sportCenter) => (
        <div className="item-card" key={sportCenter.id}>
          <img src={sportCenter.picture} alt={sportCenter.name} />
          <h3>{sportCenter.name}</h3>
          <div>
            <button onClick={() => handleEditSportCenter(sportCenter)}>Edit</button>
            <button onClick={() => handleDeleteSportCenter(sportCenter.id)}>Delete</button>
          </div>
        </div>
      ))}
      {showSportCenterForm && (
        <SportCenterForm
          sportCenter={selectedSportCenter}
          onSave={handleCreateOrUpdateSportCenter}
          onClose={() => setShowSportCenterForm(false)}
        />
      )}
      {selectedSportCenter && (
        <SelectCourt sportCenter={selectedSportCenter} />
      )}
    </div>
  );
};

export default SportCenterAdminPage;
