import React, { useState } from 'react';
import About from './About';
import Contact from './Contact';
import './CombinedPage.css';  // Create a new CSS file for styling

const CombinedPage = ({ isNative }) => {
  const [activeTab, setActiveTab] = useState('about'); // State to track the active tab

  return (
    <div className="combined-page">
      {/* Tab Navigation */}
      <div className="tab-nav">
        <button 
          className={`tab-button ${activeTab === 'about' ? 'active' : ''}`} 
          onClick={() => setActiveTab('about')}>
          About
        </button>
        <button 
          className={`tab-button ${activeTab === 'contact' ? 'active' : ''}`} 
          onClick={() => setActiveTab('contact')}>
          Contact
        </button>
      </div>

      {/* Content Section */}
      <div className="tab-content">
        {activeTab === 'about' && <About isNative={isNative} />}
        {activeTab === 'contact' && <Contact isNative={isNative} />}
      </div>
    </div>
  );
};

export default CombinedPage;
