import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../axiosConfig';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import './Register.css';

function Register({ onLogin }) {	
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
  e.preventDefault();
  
  if (formData.password !== formData.confirmPassword) {
    setMessage('Passwords do not match!');
    return;
  }

  try {
    console.log(formData);
    const response = await axios.post('/account/register', formData);
    const result = response.data;

    console.log(result);

    if (result.success) {
      const successMessage = Array.isArray(result.messagetoshow)
        ? result.messagetoshow.join('\n') // Join messages with line breaks if it's an array
        : result.messagetoshow; // If it's a single string, just display it

      setMessage(successMessage);
      navigate('/login', { state: { message: successMessage } });
    } else {
      const errorMessage = Array.isArray(result.messagetoshow)
        ? result.messagetoshow.map(msg => `• ${msg}`).join('\n') // Add bullet points and join with line breaks
        : result.messagetoshow; // If it's a single string, just display it

      setMessage(errorMessage);
    }
  } catch (error) {
    let errorMessage = 'Please try again.';
    
    if (error.response) {
      if (error.response.data && typeof error.response.data === 'string') {
        errorMessage = error.response.data; // Case where the backend returns a string error
      } else if (Array.isArray(error.response.data?.messagetoshow)) {
        errorMessage = error.response.data.messagetoshow.map(msg => `• ${msg}`).join('\n'); // Add bullet points and join with line breaks
      } else if (error.response.data?.messagetoshow) {
        errorMessage = error.response.data.messagetoshow; // Case where the backend returns the expected DTO
      } else if (error.response.data.errors) {
        // If there is a list of validation errors, format them
        errorMessage = Object.values(error.response.data.errors).flat().map(msg => `• ${msg}`).join('\n');
      } else if (error.response.data.title) {
        errorMessage = error.response.data.title; // Title of a generic validation issue
      }
    }

    setMessage(errorMessage);
  }
};

const handleGoogleRegisterSuccess = async (response) => {
  try {
    const tokenId = response.credential;
    const result = await axios.post('/account/external-login', {
      provider: 'Google',
      tokenId: tokenId,
    });

    if (result.data.success) {
      
	  toast.success("You are registered and logged in!");
	  
      const token = result.data.token;
	  
	  console.log(token);
	  
      localStorage.setItem('token', token);    
	  onLogin();
	  
      navigate('/');
    } else {
      const errorMessage = Array.isArray(result.data.messagetoshow)
        ? result.data.messagetoshow.map(msg => `• ${msg}`).join('\n') // Add bullet points and join with line breaks
        : result.data.messagetoshow; // If it's a single string, just display it

      setMessage(errorMessage);
    }
  } catch (error) {
    console.error('Error during Google registration:', error);

    // Handle potential network errors or other unforeseen errors
    let errorMessage = 'Registration with Google failed.';

    if (error.response && error.response.data) {
      if (typeof error.response.data === 'string') {
        errorMessage = error.response.data; // Simple string message from the server
      } else if (Array.isArray(error.response.data?.messagetoshow)) {
        errorMessage = error.response.data.messagetoshow.map(msg => `• ${msg}`).join('\n'); // List of messages from the server
      } else if (error.response.data?.messagetoshow) {
        errorMessage = error.response.data.messagetoshow; // Single message from the server
      } else if (error.response.data.errors) {
        errorMessage = Object.values(error.response.data.errors).flat().map(msg => `• ${msg}`).join('\n'); // Validation errors
      }
    }

    setMessage(errorMessage);
  }
};


  const handleGoogleRegisterFailure = (response) => {
    console.error('Google registration failed:', response);
    setMessage('Google registration failed.');
  };

  return (
    <GoogleOAuthProvider clientId="436125180551-d6uaqpn61sib6hovf74oknpumt9ruvqm.apps.googleusercontent.com">
      <div className="register-container">
        <form onSubmit={handleSubmit} className="register-form">
          <div className="register-input-group">
            <label>Your email address:</label>
            <input
              id="user-email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="register-input-group">
            <label>Create a password:</label>
            <input
              id="user-password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <div className="register-input-group">
            <label>Confirm your password:</label>
            <input
              id="confirm-password"
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
            />
          </div>
		   <div className="register-input-group">
            <label>Your name:</label>
            <input
              id="user-name"
              type="fullname"
              name="fullname"
              value={formData.fullname}
              onChange={handleChange}
              required
            />
          </div>
          <button id="register-button-click" type="submit" className="register-button">Register</button>
		  {message && (		  
  <div className="error-message">
  {typeof message === 'string'
    ? message.split('\n').map((msg, index) => (
        <div key={index}>{msg}</div>
      ))
    : <div>{String(message)}</div> 
  }
</div>
)}
        </form>

        <div className="social-wrapper">
          <div className="social-wrapper-title">
            <span>Or</span>
          </div>
          <div className="social-login">
            <GoogleLogin
              onSuccess={handleGoogleRegisterSuccess}
              onError={handleGoogleRegisterFailure}
              className="social-button google-button"
            />
          </div> 
        </div>

        

        <div className="register-footer">
          <span>Already have an account? <a href="/login" className="login-link">Log in</a></span>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
}

export default Register;
