import React, { useState, useEffect } from 'react';
import axios from '../../axiosConfig';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; 
import './CourtDetailsPage.css'; 

const CourtDetailsPage = ({ court, onDateSelect , updateKey }) => {    
  const [selectedDate, setSelectedDate] = useState('');
    const [availableDates, setAvailableDates] = useState([]);

  useEffect(() => {
	
    const fetchAvailableDates = async () => {
      try {
        const response = await axios.get(`/Courts/AvailableDates?Courtid=${court.id}`);
        setAvailableDates(response.data); 
      } catch (error) {
        console.error('Erro ao carregar as datas disponíveis', error);
      }
    };

    fetchAvailableDates();
  }, [court.id,updateKey]);

  // Define a data de 3 meses atrás
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

  // Conteúdo dos tiles (adicionando ponto vermelho nas datas disponíveis)
  const tileContent = ({ date, view }) => {
    if (view === 'month' && availableDates.some(d => new Date(d).toDateString() === date.toDateString())) {
      return <div className="red-dot"></div>;
    }
    return null;
  }

  const handleDateChange = (date) => {
	  console.log("sancha")
    setSelectedDate(date);
    onDateSelect(date);
  };

  return (
    <div className="court-details-page">
      <h3>Choose the date of the video!</h3>

      <div>
       <Calendar
  onChange={handleDateChange}
  value={selectedDate}
  tileContent={tileContent} /* Mostra a bolinha */
  minDate={threeMonthsAgo}  /* Data mínima ajustada */
  maxDate={new Date()}      /* Data máxima é o dia atual */
  showNeighboringMonth={false} /* Remover dias do mês anterior */
  next2Label={null} /* Remove o botão de avançar ano */
  prev2Label={null} /* Remove o botão de voltar ano */
/>
      </div>
    </div>
  );
};

export default CourtDetailsPage;
