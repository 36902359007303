import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NoUserFoto from '../images/150.png'; // Foto padrão se o usuário não tiver uma
import './Profile.css'; // Importar o CSS para estilização

const ProfilePage = ({ user, onLogout }) => {
  const navigate = useNavigate(); 

  // Redirecionar para a página inicial se o usuário não estiver logado
  useEffect(() => {
    if (!user) {
      navigate('/'); 
    }
  }, [user, navigate]);

  const handleLogout = () => {
    onLogout();
    navigate('/'); 
  };

  if (!user) {
    return null; // Evita renderizar até redirecionar
  }

  return (
    <div className="profile-container">
      <div className="profile-header">
        <h2>My Profile</h2>
      </div>
      
      <div className="profile-content">
        <div className="profile-avatar">
          <img src={user.avatarUrl || NoUserFoto} alt="User Avatar" className="avatar-img" />
        </div>
        
        <div className="profile-details">
          <h3>Welcome, {user.name}!</h3>
        </div>
        
        <div className="profile-actions">
          <button className="profile-button" onClick={handleLogout}>Logout</button>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
