// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ isAuthenticated, children }) => {
  if (!isAuthenticated) {
    // Se o usuário não está autenticado, redireciona para a página de login
    return <Navigate to="/login" replace />;
  }

  // Se o usuário está autenticado, renderiza o conteúdo da rota
  return children;
};

export default ProtectedRoute;
