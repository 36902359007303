import axios from 'axios';

let baseURL;

baseURL = process.env.REACT_APP_API_BASE_URL;

const instance = axios.create({
  baseURL: baseURL
});

instance.interceptors.request.use(
  (config) => {	  
    const token = localStorage.getItem('token');	
    if (token) {		
      config.headers['Authorization'] = `Bearer ${token}`;  // Adiciona o token ao cabeçalho Authorization	  
    }	
    return config;
  },
  (error) => {	  
    return Promise.reject(error);
  }
);

export default instance;
