import React, { useState, useEffect } from 'react';

const CourtForm = ({ court, sportCenterId, onSave, onClose }) => {
  const [name, setName] = useState('');
  const [id, setId] = useState(null);

  useEffect(() => {
    if (court) {
      setName(court.name || '');
      setId(court.id || null);
    }
  }, [court]);

  const handleSubmit = () => {
    const newCourt = {
      id,  
      name,
      sportCenterId,  // Inclui o sportCenterId ao criar uma nova quadra
    };
    onSave(newCourt);
  };

  return (
    <div className="form-container">
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Nome da Quadra"
      />
      <button onClick={handleSubmit}>Salvar</button>
      <button onClick={onClose}>Cancelar</button>
    </div>
  );
};

export default CourtForm;
